import { defineStore } from 'pinia';
import type { EquipmentPersona } from '#shared/types';
import type { Graph } from '#shared/Graph';
import {
  type DateRange,
  dateRangeForTimeScaleIncludingDate,
  getTimescaleLabelsForRange,
  slideDateRangeWindowForTimeScale,
  type TimeScaleId,
} from '#shared/time-scales';
import { fetchCpoGraph } from '~/services/charges.service';
import { match } from 'ts-pattern';
import moment from 'moment';

export type LoadableGraph =
  | { loadingStatus: 'not-loading' | 'loading' | 'error' }
  | { loadingStatus: 'loaded'; graph: Graph };

type CachedGraphsKey = `${EquipmentPersona}|dateRange[${number}-${number}]|searchHash(${string})`;

export type CachedGraphs = {
  [key in CachedGraphsKey]?: LoadableGraph | undefined;
};

export type ChartDatasets<D> = Array<{ type: string; label: string; backgroundColor: string; data: D }>;
export type ChartData = {
  labels: string[] | number[];
  datasets: ChartDatasets<number[]>;
};
export type EmptyChartData = ChartData & {
  datasets: ChartDatasets<[]>;
};

export type LoadableChart =
  | { loadingStatus: 'not-loading' | 'loading' | 'error'; chartData: EmptyChartData }
  | { loadingStatus: 'loaded'; chartData: ChartData };

type SearchCriteria = {
  hash: string;
  sortedBorneIds: string[];
};

function cachedGraphsKeyOf(
  persona: EquipmentPersona,
  dateRange: DateRange,
  searchCriteria: SearchCriteria,
): CachedGraphsKey {
  return `${persona}|dateRange[${dateRange.start.valueOf()}-${dateRange.end.valueOf()}]|searchHash(${searchCriteria.hash})`;
}

export const useChargeCharts = defineStore('charge-graphs', () => {
  const { currentEquipmentPersona, currentPersonaEquipmentIdsRef } = storeToRefs(useEquipments());

  const _cachedGraphs: CachedGraphs = reactive({});

  const currentTimescaleRef = ref<TimeScaleId>('yearly');
  // Updating date range whenever timescale is updated
  watch([currentTimescaleRef], ([timescale]) => {
    const currentDateRange = toValue(_currentDateRangeRef);
    _currentDateRangeRef.value = dateRangeForTimeScaleIncludingDate(
      timescale,
      moment().set('year', currentDateRange.start.get('year')).toDate(),
    );
  });

  const _currentDateRangeRef = ref(dateRangeForTimeScaleIncludingDate(currentTimescaleRef.value, new Date()));
  const moveCurrentDateRange = async (direction: 'next-1' | 'previous-1') => {
    _currentDateRangeRef.value = slideDateRangeWindowForTimeScale(
      _currentDateRangeRef.value,
      direction,
      currentTimescaleRef.value,
    );
  };

  const searchCriteriaRefs = reactive({
    borneIds: [] as string[],
  });
  const applicableSearchCriteriaRef = computed<SearchCriteria>(() => {
    const currentPersonaEquipmentIds = toValue(currentPersonaEquipmentIdsRef),
      userBorneIds = toValue(searchCriteriaRefs.borneIds);

    const borneIds = userBorneIds.length ? userBorneIds : currentPersonaEquipmentIds?.borneIds || [];

    // Sorting borne ids so that selection order doesn't impact search criteria hash
    const sortedBorneIds = [...borneIds].sort();

    return {
      hash: [
        `borneIds:${sortedBorneIds.join(',')}`,
        // add new criteria here if needed in the future
      ].join('|'),
      // Falling back on "all bornes" when no borne is selected
      sortedBorneIds,
    };
  });

  const currentCachedGraphKey = computed<CachedGraphsKey>(() => {
    return cachedGraphsKeyOf(
      toValue(currentEquipmentPersona),
      toValue(_currentDateRangeRef),
      toValue(applicableSearchCriteriaRef),
    );
  });
  const currentLoadableGraphRef = computed<LoadableGraph>(() => {
    const key = toValue(currentCachedGraphKey);
    return _cachedGraphs[key] || { loadingStatus: 'not-loading' };
  });
  // Making a new call to getGraphs whenever getGraphs params change
  // (and avoiding call if we already have graph stats in store's in-memory cache)
  watch(
    [
      _currentDateRangeRef,
      currentTimescaleRef,
      currentEquipmentPersona,
      currentPersonaEquipmentIdsRef,
      applicableSearchCriteriaRef,
    ],
    async ([dateRange, timescale, persona, personaEquipmentIds, applicableSearchCriteria]) => {
      const key = cachedGraphsKeyOf(persona, dateRange, applicableSearchCriteria);

      // Already loading/loaded graph => skipping
      if (_cachedGraphs[key] && _cachedGraphs[key].loadingStatus !== 'not-loading') {
        return;
      }

      _cachedGraphs[key] = {
        loadingStatus: 'loading',
      };

      try {
        const graph = await fetchCpoGraph({
          dateRange: dateRange,
          timeScale: timescale,
          borneIds: applicableSearchCriteria.sortedBorneIds,
          // No filtering on badge/drivers currently
          badgeIds: personaEquipmentIds?.badgeIds || [],
          driverEmaIds: personaEquipmentIds?.driverEmaIds || [],
        });

        _cachedGraphs[key] = {
          loadingStatus: 'loaded',
          graph,
        };
      } catch (error: unknown) {
        console.error(`Error while loading charge graph for key=${key}: `, error);

        _cachedGraphs[key] = {
          loadingStatus: 'error',
        };
      }
    },
    // Need to trigger a call from the beginning
    { immediate: true },
  );

  // Re-calculating chart data whenever graph data is updated
  const currentPersonaChartDataRef = ref<LoadableChart>(
    createLoadableChartFrom({ loadingStatus: 'not-loading' }, currentTimescaleRef.value, _currentDateRangeRef.value),
  );
  watch(
    [currentLoadableGraphRef, currentTimescaleRef, _currentDateRangeRef],
    ([loadableGraph, timescale, dateRange]) => {
      currentPersonaChartDataRef.value = createLoadableChartFrom(loadableGraph, timescale, dateRange);
    },
    { immediate: true },
  );

  const resetCachedChargeCharts = () => {
    Object.keys(_cachedGraphs).forEach((key: string) => {
      _cachedGraphs[key as CachedGraphsKey] = undefined;
    });
    searchCriteriaRefs.borneIds = [];
    currentTimescaleRef.value = 'yearly';
    _currentDateRangeRef.value = dateRangeForTimeScaleIncludingDate(currentTimescaleRef.value, new Date());
    currentPersonaChartDataRef.value = createLoadableChartFrom(
      { loadingStatus: 'not-loading' },
      currentTimescaleRef.value,
      _currentDateRangeRef.value,
    );
  };

  return {
    searchCriteriaRefs,
    currentDateRangeRef: computed(() => toValue(_currentDateRangeRef)),
    currentTimescaleRef,
    currentPersonaChartDataRef,
    moveCurrentDateRange,
    resetCachedChargeCharts,
  };
});

function createChartDatasetFrom<D>({
  badgesCharges,
  bornesCharges,
}: {
  badgesCharges: D;
  bornesCharges: D;
}): ChartDatasets<D> {
  return [
    {
      type: 'bar',
      label: 'Mes charges (en kWh)',
      backgroundColor: '#2D2E83',
      data: badgesCharges,
    },
    {
      type: 'bar',
      label: 'Autres charges (en kWh)',
      backgroundColor: '#36A9E1',
      data: bornesCharges,
    },
  ];
}

function createLoadableChartFrom(
  loadableGraph: LoadableGraph,
  timescale: TimeScaleId,
  dateRange: DateRange,
): LoadableChart {
  const labels = getTimescaleLabelsForRange(timescale, dateRange);
  return match(loadableGraph)
    .with({ loadingStatus: 'loaded' }, ({ loadingStatus, graph }) => {
      return {
        loadingStatus,
        chartData: {
          labels,
          datasets: createChartDatasetFrom({ ...graph }),
        },
      };
    })
    .otherwise(({ loadingStatus }) => ({
      loadingStatus,
      chartData: {
        labels,
        datasets: createChartDatasetFrom<[]>({ badgesCharges: [], bornesCharges: [] }),
      },
    }));
}
