import { match } from 'ts-pattern';
import moment from 'moment';
import { rangedValues } from '#shared/arrays';

export const AVAILABLE_TIME_SCALES = [
  { id: 'monthly', label: 'par jour' },
  { id: 'yearly', label: 'par mois' },
] as const;
export type TimeScaleId = (typeof AVAILABLE_TIME_SCALES)[number]['id'];

export type DateRange = { start: moment.Moment; end: moment.Moment };

export type TimeScalePeriod = `${'yearly'}|${number}` | `${'monthly'}|${number}-${number}`;

export function dateRangeForTimeScaleIncludingDate(timeScale: TimeScaleId, dateHavingToBeIncludedInRange: Date) {
  const momentHavingToBeIncludedInRange = moment(dateHavingToBeIncludedInRange);
  const unitOfTime = match(timeScale)
    .with('yearly', () => 'year' as const)
    .with('monthly', () => 'month' as const)
    .exhaustive();

  const start = momentHavingToBeIncludedInRange.clone().startOf(unitOfTime);
  return {
    start,
    end: start.clone().add(1, unitOfTime),
  };
}

export function slideDateRangeWindowForTimeScale(
  dateRange: DateRange,
  movingWindow: 'next-1' | 'previous-1',
  timeScale: TimeScaleId,
): DateRange {
  const unitOfTime = match(timeScale)
    .with('yearly', () => 'year' as const)
    .with('monthly', () => 'month' as const)
    .exhaustive();

  const slidingValue = match(movingWindow)
    .with('next-1', () => 1 as const)
    .with('previous-1', () => -1 as const)
    .exhaustive();

  return dateRangeForTimeScaleIncludingDate(timeScale, dateRange.start.clone().add(slidingValue, unitOfTime).toDate());
}

export function getTimeScaleIndexFromDate(timeScale: TimeScaleId, date: Date): number {
  if (timeScale === 'yearly') {
    return date.getMonth(); // first month is 0
  } else {
    return date.getDate() - 1; // first day is 1
  }
}

export function getTimescaleLabelsForRange(timeScale: TimeScaleId, dateRange: DateRange) {
  return match(timeScale)
    .with('yearly', () => [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ])
    .with('monthly', () => {
      return rangedValues(1, dateRange.start.daysInMonth());
    })
    .exhaustive();
}

export function timescalePeriodOf(timeScale: TimeScaleId, dateRange: DateRange): TimeScalePeriod {
  return match(timeScale)
    .with('yearly', () => `yearly|${dateRange.start.get('year')}` as const)
    .with(`monthly`, () => `monthly|${dateRange.start.get('year')}-${dateRange.start.get('month')}` as const)
    .exhaustive();
}
